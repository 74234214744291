<template>
  <div class="page-info-content">
    <!-- 收款详情 -->
    <DetailsPage details-title="基本信息" :detail-obj="detailsObj" :details-item-arr="detailsItemArr" :column="2" border />
    <div class="invoice-box">
      <h3 class="page-subtitle-shade">
        合同信息
      </h3>
      <Table :item-data="detailsPageitemData" :list-data="detailsObj.contractParams">
        <template #contractUrl="{row}">
          <div class="invoice-url" @click="viewUrl(row.contractUrl)">
            {{ row.contractUrl }}
          </div>
        </template>
      </Table>
      <div class="flex-row">
        <h3 class="page-subtitle-shade">
          发票信息
        </h3>
        <el-button @click="download">
          一键下载发票
        </el-button>
      </div>
      <Table :item-data="itemData" :list-data="listData">
        <template #invoiceUrl="{row}">
          <div class="invoice-url" @click="viewUrl(row.invoiceUrl)">
            {{ row.invoiceUrl }}
          </div>
        </template>
      </Table>
      <div>总价： <span>{{ invoiceTotalPrice }}</span></div>
    </div>
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage2'
import Table from '@/components/Table'
import { payalbeBillInfoDetail, payalbeBillInfoToManageDownload } from '@/api/tradeFinancing.js'
// import { downloadFile2 } from '@/utils/util.js'
// import { scfURL } from '@/systemConfiguration/index'
export default {
  components: { DetailsPage, Table },
  data() {
    return {
      detailsObj: {},
      detailsItemArr: [
        { label: '应收/应付账款内部编码', value: 'billNo' },
        { label: '企业名称(卖方)', value: 'sellCmpName' },
        { label: '企业名称(买方)', value: 'buyCmpName' },
        { label: '应收/应付账款到期日', value: 'acctDate' },
        { label: '应收/应付账款金额', money: 'billAmt' },
        { label: '已收金额', money: 'receAmt' },
        { label: '数据来源', value: 'dataSource', child: [{ dictName: '系统录入', dictId: 1 }, { dictName: 'ERP', dictId: 2 }] },
        { label: '确认状态', value: 'billStat', child: [{ dictName: '未确认', dictId: '01' }, { dictName: '待确认', dictId: '02' }, { dictName: '已确认', dictId: '03' }] },
        { label: '转让状态', value: 'transStat', child: [{ dictName: '待转让', dictId: '01' }, { dictName: '已转让', dictId: '02' }, { dictName: '未转让', dictId: '03' }, { dictName: '部分转让', dictId: '04' }] },
        { label: '收款/付款状态', value: 'moneyState', child: [{ dictName: '未付款', dictId: '01' }, { dictName: '部分付款', dictId: '02' }, { dictName: '已付款', dictId: '03' }] },
        { label: '状态', value: 'state' }
      ],
      listData: [],
      itemData: [
        { label: '发票代码', prop: 'invoiceCode', width: 100 },
        { label: '发票号码', prop: 'invoiceNo', width: 100 },
        { label: '校验码', prop: 'checkCode', width: 100 },
        { label: '发票金额', prop: 'invoiceAmt', width: 100 },
        { label: '不含税金额', prop: 'invoiceReceAmt', width: 100 },
        { label: '开票日期', prop: 'invoiceDate', width: 200 },
        { label: '合同编码', prop: 'contractNo', width: 200 },
        { label: '上传发票', prop: 'invoiceUrl', width: 300 },
        { label: '销售方名称', prop: 'sellCmpName', width: 200 },
        { label: '销售方社会信用代码', prop: 'sellCmpUnicode', width: 200 },
        { label: '采购方名称', prop: 'buyCmpName', width: 200 },
        { label: '采购方社会信用代码', prop: 'buyCmpUnicode', width: 200 }
      ],
      detailsPageitemData: [
        { label: '合同编号', prop: 'contractNo' },
        { label: '合同签订日期', prop: 'contractDate' },
        { label: '合同金额', prop: 'contractAmt' },
        { label: '合同附件', prop: 'contractUrl', width: 140 }
      ],
      invoiceTotalPrice: 0
    }
  },
  created() {
    if (this.$route.query.id) this.getDetails()
  },
  methods: {
    getDetails() {
      payalbeBillInfoDetail(this.$route.query.id, res => {
        this.detailsObj = res.data
        this.listData = res.data.invoiceParams
        let invoiceTotalPrice = 0
        this.listData.map((item) => {
          item.invoiceUrl = item.billImages && item.billImages[0] && item.billImages[0].mongoId
          invoiceTotalPrice += item.invoiceAmt
          return item
        })
        this.invoiceTotalPrice = invoiceTotalPrice
        this.detailsObj.contractParams.map((item) => {
          item.contractUrl = item.contractAttachs && item.contractAttachs[0] && item.contractAttachs[0].mongoId
          return item
        })
      })
    },
    download() {
      // downloadFile2(`${scfURL}/v1/payalbeBillInfoToManage/download/${this.$route.query.id}`, '应收账款', 'xlsx')
      payalbeBillInfoToManageDownload(this.$route.query.id, res => {
        const downloadUrl = window.URL.createObjectURL(
        // 对后端返回文件流类型处理
          new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
          })
        )
        // word文档为msword,pdf文档为pdf
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', '应收账款.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    viewUrl(url) {
      window.open(this.$fileUrl + url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.invoice-box {
  padding: 20px;
}
.flex-row {
  display: flex;
  margin-top: 40px;
  button {
    height: 50px;
  }
}
.invoice-url {
  color: #0089FF;
  cursor: pointer;
}
</style>
